import { Box, Container, Heading, Text, useColorMode } from "@chakra-ui/react";
import React, { useEffect } from "react";
import Line from "../components/DefinitionComponents/Complete-Components/Line";
import InViewTrigger from "../components/DefinitionComponents/Utility/UX/InViewTrigger";
import GImage from "../components/DefinitionComponents/GImage";
import Layout from "../components/layout/Layout";
import FadeInAnimation from "../components/DefinitionComponents/Animation-Components/FadeInAnimation";
import AnimatedText from "../components/DefinitionComponents/Complete-Components/AnimatedText";
import ImgTextModule from "../components/DefinitionComponents/ImageTextModule";

const Index = () => {
	const { setColorMode } = useColorMode();
	useEffect(() => {
		setColorMode("light");
	});
	return (
		<Layout
			title={"Legal notice / Impressum"}
			description={
				"We drive brands. Build platforms. Shape businesses."
			}>
			<ImgTextModule
				src={"14mini.mp4"}
				delay={0.3}
				duration={0.5}
				threshold={0.3}
				initialY={10}
				initialX={10}
				video={true}
				h="85vh"
				mediaOpacity={0.7}
				bgColor="brand.gray.100">
				<Container variant="layoutContainer" mt={8} px={8} pt={24}>
					<Box pb={24}>
						<GImage
							width="10%"
							src="dfn-logo-asia-white-small.svg"
						/>

						<InViewTrigger threshold={0.1} triggerOnce={false}>
							{(inView) => (
								<Line
									maxW="50%"
									duration={1}
									delay={0}
									inView={inView}
									bg={"brand.green.primary"}
									h={"1px"}
									my={2}
								/>
							)}
						</InViewTrigger>
						<AnimatedText
							tag={"h2"}
							mb={4}
							lineHeight="undercut"
							text={"Legal notice / Impressum"}
							color={"brand.green.primary"}
							lineHeight="1"
							size={{ base: "3xl", md: "3xl", lg: "7xl" }}
							maxW={"4xl"}
							threshold={0.2}
							triggerOnce={true}
							staggerChildren={0.02}
							id={"dfn1-titlesub1"}
						/>
						<AnimatedText
							tag={"h3"}
							mb={4}
							lineHeight="undercut"
							text={"Definition Digital Products Development + Platforms GmbH"}
							color={"brand.gray.almostwhite"}
							lineHeight="1"
							size={{ base: "3xl", md: "3xl", lg: "5xl" }}
							maxW={"4xl"}
							threshold={0.2}
							triggerOnce={true}
							staggerChildren={0.02}
							id={"dfn1-titlesub1"}
						/>
						<FadeInAnimation
							duration={1}
							delay={0}
							initialY={-5}
							threshold={0.4}>
							<Heading
								color={"brand.gray.1000"}
								fontSize={{ base: "xl", md: "xl", lg: "xl" }}
								fontWeight="normal"
								w={"100%"}
								letterSpacing="tighter">
								hello@definition.group
							</Heading>
							<Heading
								color={"brand.gray.1000"}
								fontSize={{ base: "xl", md: "xl", lg: "xl" }}
								fontWeight="normal"
								w={"100%"}
								letterSpacing="tighter">
								Phone: +49 4461 918 7684
							</Heading>
						</FadeInAnimation>
					</Box>
				</Container>
			</ImgTextModule>
			<Container variant="layoutContainer" py={8} px={8} pt={16}>
				<AnimatedText
					tag={"h3"}
					mb={4}
					lineHeight="undercut"
					text={"Informationspflicht laut § 5 TMG."}
					color={"brand.gray.300"}
					lineHeight="1"
					size={{ base: "3xl", md: "3xl", lg: "5xl" }}
					maxW={"4xl"}
					threshold={0.2}
					triggerOnce={true}
					staggerChildren={0.02}
					id={"dfn1-titlesub1"}
				/>
				<Text>
					<br />
					Definition Digital Products Development + Platforms GmbH<br />
					<br />			
					Am Hillernsen Hamm 51<br />
					26441 Jever<br />
					Deutschland/Germany<br />
					<br />
					E-Mail: hello@definition.group<br />
					Telefon: +49 4461 918 7684<br />
					<br />
					Handelsregister:<br />
					HRB 207549<br />
					<br />			
					Gerichtsstand:<br />
					Amtsgericht Oldenburg<br />
					<br />
					Redaktionell verantwortlich: Martin Adelstein<br />
				</Text>
			</Container>
		</Layout>
	);
};

export default Index;
